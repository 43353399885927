<template>
	<div>
		<div class="form-body">
			<el-form ref="form" :model="formData" label-width="150rem" :rules="rules">
				<el-form-item label="考试名称" prop="exam_id">
					<!-- <el-select :popper-append-to-body="false" v-model="formData.exam_id" @change="examidChange" disabled>
						<el-option v-for="item in exam_name_arr" :key="item.value" :label="item.label" :value="item.value" />
					</el-select> -->
					<!-- <el-input v-model="formData.exam_name" disabled></el-input> -->
					{{ formData.exam_name }}
				</el-form-item>
				<el-form-item label="总成绩本科线分数" prop="total_benco_score">
					<span>{{ $tools.formatNumber(formData.total_benco_score) }}</span>
				</el-form-item>
				<el-form-item label="总成绩一本线分数" prop="total_yiben_score">
					<span>{{ $tools.formatNumber(formData.total_yiben_score) }}</span>
				</el-form-item>
				<div class="knowledge" v-for="(item, index) in formData.course" :key="index">
					<el-col :span="20" class="knowledge_box">
						<el-col :span="8">
							<el-form-item label="科目">
								<!-- <el-select :popper-append-to-body="false" v-model="formData.course[index].subject_id" disabled>
									<el-option v-for="item in subjects_arr" :key="item.value" :label="item.label" :value="item.value" />
								</el-select> -->
								<span style="width: 150rem !important">{{ formData.course[index].subject_name }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="本科线分数">
								<!-- <el-input-number
									disabled
									v-model="formData.course[index].benco_score"
									:min="0"
									:max="1000"
									:step="0.5"
									step-strictly
									@change="bencoScore(index)"
									style="width: 130rem !important"
								></el-input-number> -->
								<span style="width: 150rem !important">{{ formData.course[index].benco_score }}</span>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="一本线分数">
								<!-- <el-input-number
									disabled
									v-model="formData.course[index].yiben_score"
									:min="0"
									:max="1000"
									:step="0.5"
									step-strictly
									@change="yibenScore(index)"
									style="width: 130rem !important"
								></el-input-number> -->
								{{ formData.course[index].yiben_score }}
							</el-form-item>
						</el-col>
					</el-col>
					<!-- 关联知识点新增删除题目按钮 -->
					<el-col :span="4" class="add_knowledge">
						<!-- <el-button icon="el-icon-delete" circle @click="deleteKnowledge"></el-button>
						<el-button icon="el-icon-plus" circle @click="addKnowledge" v-if="formData.course.length - 1 == index"></el-button> -->
					</el-col>
				</div>
			</el-form>
		</div>
		<div class="footer-button">
			<!-- <el-button type="primary" @click="submit('form')">提交</el-button> -->
			<el-button @click="() => this.$router.back()" type="primary">返回</el-button>
		</div>
	</div>
</template>

<script>
import { exam, subject, putExam, setExam } from './api.js'
export default {
	_config:{"route":{"path":"look","meta":{"title":"查看"}}},
  data() {
		return {
			// 考试科目
			subjects_arr: [],
			// 考试名称
			exam_name_arr: [],

			// 表单数据
			formData: {
				exam_id: '', // 考试名称
				// 总成绩本科线分数
				total_benco_score: 0,
				// 总成绩一本线分数
				total_yiben_score: 0,
				// 考试信息统计
				course: [
					{
						subject_id: '', //		科目ID
						benco_score: '', //	本科分数线
						yiben_score: '' //	一本分数线
					}
				]
			},
			// 表单验证规则
			rules: {
				exam_id: [{ required: true, message: '请选择考试名称', trigger: 'change' }],
				total_benco_score: [{ required: true, message: '请填写你的本科分数线', trigger: 'change' }],
				total_yiben_score: [{ required: true, message: '请填写你的一本分数线', trigger: 'change' }]
			}
		}
	},
	methods: {
		examidChange(val) {
			this.formData.course.forEach(item => {
				item.subject_id = ''
			})
			// 获取考试科目
			subject(val).then(res => {
				this.subjects_arr = JSON.parse(
					JSON.stringify(res.data.data)
						.replace(/id/g, 'value')
						.replace(/subject_name/g, 'label')
				)
			})
		},
		// 本科分数线变化
		bencoScore() {
			this.formData.total_benco_score = 0
			for (let i = 0; i < this.formData.course.length; i++) {
				this.formData.total_benco_score += this.formData.course[i].benco_score
			}
		},
		// 一本分数线变化
		yibenScore() {
			this.formData.total_yiben_score = 0
			for (let i = 0; i < this.formData.course.length; i++) {
				this.formData.total_yiben_score += this.formData.course[i].yiben_score
			}
		},
		// 删除知识点
		deleteKnowledge() {
			if (this.formData.course.length > 1) {
				this.formData.course.splice(this.$index, 1)
			} else {
				this.$message.error('至少保留一条知识点')
			}
			this.yibenScore()
			this.bencoScore()
		},
		// 添加知识点
		addKnowledge() {
			this.formData.course.push({
				subject_id: '', //		科目ID
				benco_score: '', //	本科分数线
				yiben_score: '' //	一本分数线
			})
		},
		// 表单提交
		submit() {
			this.$refs.form.validate(valid => {
				if (valid) {
					let curseIds = {}
					for (let i = 0; i < this.formData.course.length; i++) {
						let item = this.formData.course[i]
						if (this.formData.course[i].subject_id == '') {
							this.$message.error('请输入完整的信息')
							return
						}
						if (this.formData.course[i].benco_score == 0 || this.formData.course[i].yiben_score == 0) {
							this.$message.error('请输入完整的信息')
							return
						}
						if (curseIds[item.subject_id] == null) curseIds[item.subject_id] = true
						else {
							this.$message.error('考试科目不可重复')
							return
						}
					}
					putExam(this.$route.query.id, this.formData).then(res => {
						if (res.data.error.errorCode == 0) {
							this.$message.success('编辑成功')
							this.$router.back()
						} else {
							this.$message.error('编辑失败')
						}
					})
				} else {
					this.$message.error('请输入完整的信息。')
				}
			})
		},
		// 取消按钮
		cancel() {
			this.$confirm('是否取消编辑?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.back()
			})
		}
	},
	created() {
		// 获取考试名称
		exam().then(res => {
			this.exam_name_arr = JSON.parse(
				JSON.stringify(res.data.data)
					.replace(/exam_id/g, 'value')
					.replace(/exam_name/g, 'label')
			)
			this.exam_name_arr.forEach(item => {
				item.value = item.value + ''
			})
		})

		// 分数统计设置详情
		setExam(this.$route.query.id).then(res => {
			this.formData = res.data.data
			// 获取考试科目
			subject(res.data.data.exam_id).then(res => {
				this.subjects_arr = JSON.parse(
					JSON.stringify(res.data.data)
						.replace(/id/g, 'value')
						.replace(/subject_name/g, 'label')
				)
				this.subjects_arr.forEach(item => {
					item.value = Number(item.value)
				})
			})
			this.formData.total_benco_score = this.formData.benco_score_sum
			this.formData.total_yiben_score = this.formData.yiben_score_sum
		})
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content{
  margin-left: 170rem !important;
}
::v-deep .knowledge .el-form-item__content{
  margin-left: 150rem !important;
}
.form-body{
  padding-right: 0;
}
.knowledge {
	//height: 150rem;
	margin-top: 24rem;
	display: flex;
	justify-content: center;
	align-items: center;
	.knowledge_box {
		padding-top: 12rem;
		height: 60rem;
		background-color: #f4f4f4;
	}
	.add_knowledge {
		padding-left: 40rem;
	}
}
//@media only screen and (max-width: 1500rem) {
//	.knowledge {
//		width: 1200rem !important;
//	}
//}
</style>
